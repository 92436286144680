import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { color, mq, fontFamily, width } from "../utils/style-components"
import { color as csxColor } from "csx"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HexBg from "../img/common/hex_bg.svg"

const NotFound: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Wrap>
        <Inner>
          <Title>404 Not Found</Title>
          <p
            css={css`
              text-align: center;
            `}
          >
            お探しのページは見つかりませんでした。
          </p>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: min(100px, 11vw);
            `}
          >
            <Link
              to="/"
              state={{ skipMovie: true }}
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 1;
                color: ${color.white};
                background: ${color.blue};
                width: 270px;
                height: 70px;
              `}
            >
              TOPに戻る
            </Link>
          </div>
        </Inner>
      </Wrap>
    </Layout>
  )
}

const ListItem = styled.li`
  background: ${color.blue};
  display: flex;
  flex-direction: column;
  ${mq(
    `pc`,
    css`
      flex: 0 0 48%;
    `
  )}
  ${mq(
    `sp`,
    css`
      min-height: 150px;
      li + & {
        margin-top: min(60px, 10vw);
      }
    `
  )}
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.8rem;
    text-align: center;
    text-decoration: none;
    color: ${color.white};
    ${mq(
      `pc`,
      css`
        padding: 90px 0;
      `
    )}
    ${mq(`sp`, css``)}
  }
`

export const Wrap = styled.div`
  overflow: hidden;
  background: top left / 461px 180px url(${HexBg}) ${color.grayLight};
`
export const Inner = styled.div`
  position: relative;
  margin: min(274px, 50vw) auto min(380px, 20vw);
  padding: min(120px, 16vw) min(66px, 2.5vw) min(90px, 14vw);
  max-width: ${width.contentInner};
  width: ${width.contentSP};
  background: ${csxColor(color.white).fade(0.75).toString()};
`

export const Title = styled.h2`
  position: absolute;
  left: 0;
  font-family: ${fontFamily.decorate1};
  font-weight: normal;
  line-height: 1;
  color: ${color.blue};
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  ${mq(
    `pc`,
    css`
      font-size: 8rem;
      top: -4rem;
    `
  )}
  ${mq(
    `sp`,
    css`
      font-size: 4.2rem;
      top: -2rem;
    `
  )}
`

export default NotFound
